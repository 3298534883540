<template>
	<div class="my_guanz">
		<div class="home_center">

			<div class="">
				<div class="center_left">
					<div class="left_user">
						<img :src="userInfo.avatar_url" class="user_img" alt="">
						<div class="user_right">
							<div class="right_name">
								{{userInfo.nickname}}
							</div>
							<div class="right_msg">
								{{userInfo.profession}}
							</div>
							<div class="right_msg">
								{{userInfo.city}}
							</div>
						</div>
					</div>
					<el-button type="primary" :class="showIndex==1?'active':'btn'" @click="tab(1)"><!-- 我的关注 -->{{$t("header.WDGZ")}}</el-button>
					<el-button type="primary"  :class="showIndex==2?'active':'btn'" @click="tab(2)"><!-- 我的粉丝 -->{{$t("task.WDFS")}}</el-button>
					<div class="left_tui" style="display: none;">
						<div class="tui_title">
							<!-- 推荐工作 -->{{$t("task.TJGZ")}}
						</div>
						<div class="tui_box">
							<div class="tui_list">
								<!-- 工作名称 -->{{$t("task.GZMC")}}：Yours的UI设计
							</div>
							<div class="tui_list">
								<!-- 发布者 -->{{$t("FBZ")}}：阿里巴巴
							</div>
							<div class="tui_list">
								<!-- 工作佣金 -->{{$t("task.GZYJ")}}：¥1000
							</div>
							<div class="tui_list">
								<!-- 工作类目 -->{{$t("task.GZLM")}}：设计/网页设计/UI设计任务量：10days
							</div>
							<div class="tui_list">
								<!-- 需要人数 -->{{$t("other.XYRS")}}：2
							</div>
							<router-link to="/job_detail">
								<el-button type="primary" class="tui_btn"><!-- 申请工作 -->{{$t("job_detail.SQGZ")}}</el-button>
								
							</router-link>
						</div>
						
						<el-button type="primary" class="tui_change"><!-- 换一换 -->{{$t("task.HYH")}}</el-button>
					</div>
					<div class="left_tui" style="margin-top: 10px; display: none;">
						<div class="tui_title">
							<!-- 推荐活动 -->{{$t("task.TJHD")}}
						</div>
						<div class="tui_box">
							<div class="tui_list">
								<!-- 活动名称 -->{{$t("add_activity.HDMC")}}：腾讯线下招聘会
							</div>
							<div class="tui_list">
								<!-- 发布者 -->{{$t("FBZ")}}：阿里巴巴
							</div>
							<div class="tui_list">
								<!-- 聚集地址 -->{{$t("task.JJDZ")}}：腾讯大厦12楼1205室
							</div>
							<div class="tui_list">
								<!-- 活动主题 -->{{$t("activity.HDZT")}}：招聘
							</div>
							<div class="tui_list">
								<!-- 活动时间 -->{{$t("activity.HDSJ")}}：(UTC+09:00）东京大阪
							</div>
							<router-link to="/job_detail">
								<el-button type="primary" class="tui_btn"><!-- 参加 -->{{$t("activity.CJ")}}</el-button>
								
							</router-link>
						</div>
						<el-button type="primary" class="tui_change"><!-- 换一换 -->{{$t("task.HYH")}}</el-button>
					</div>
				</div>
				
			</div>


			<div class="center_people" >
				<div class="people_top">
					<div class="top_left_box" @click="back">
						<img src="../../assets/img/arrow-left-bold.png" class="top_left" alt="">
						<!-- 返回 -->{{$t("home.FH")}}
					</div>
					
					<div class="top_center" v-if="showIndex==1">
						<!-- 全部关注 -->{{$t("QBGZ")}}（{{fllowTotal}}）
					</div>
					<div class="top_center" v-if="showIndex==2">
						<!-- 全部粉丝 -->{{$t("task.QBFS")}}（{{fansTotal}}）
					</div>
					<el-input :placeholder="$t('USEKEYWORD')" class="input_right"  v-model="listData.keyword" clearable @keyup.enter.native="" @input="search"></el-input>
					<!-- <el-input v-model="listData.keyword" class="input_right" placeholder="请输入内容"></el-input> -->
				</div>
				<div class="people_list" v-if="showIndex==1" v-loading="loading"  element-loading-background="rgba(0, 0, 0, 0.8)">
					<div class="list_box"   v-for="(item,index) in list" :key="item.user_fans_id">
						<div class="list_img">
							<img :src="item.follow_avatar_url" @click.stop="toUserPage(item.follow_who_id,item.follow_who_role)"  alt="">
						</div>
						<div class="list_text" style="min-width:0;" @click.stop="toUserPage(item.follow_who_id,item.follow_who_role)">
							<div class="text_name">
								{{item.follow_open_name}}<template v-if="item.follow_profession">/{{item.profession_follow}}</template>
								<span>8月15日 8:15</span>
							</div>
							<div class="text_msg" >
								{{item.follow_description}}
							</div>
						</div>
						<div class="btn_right">
							<el-button type="primary" class="btn1" @click="sendmsg(item.follow_who_id)"><!-- 发送消息 -->{{$t('FSXX')}}</el-button>
							<el-button type="primary" class="btn2" @click="guanzhu(item.follow_who_id,item.follow_who_role,index)"><!-- 取消关注 -->{{$t('QXGZ')}}</el-button>
							<!-- <el-button type="primary" class="btn3">已关注</el-button> -->
							<!-- <img src="../../assets/img/more.png" class="more" alt=""> -->
						</div>
					</div>
					
					<!-- <div class="list_none"  v-if="isLast==1">
						{{$t('task.ZWGDGZ')}}
					</div> -->
				</div>
			
				
				<div class="people_list" v-if="showIndex==2" v-loading="loading"  element-loading-background="rgba(0, 0, 0, 0.8)">
					<div class="list_box" v-for="(item,index) in list" :key="item.user_fans_id">
						<img :src="item.avatar_url_follow"  @click.stop="toUserPage(item.who_id_follow,item.who_role_follow)" class="list_img" alt="">
						<div class="list_text"  @click.stop="toUserPage(item.who_id_follow,item.who_role_follow)">
							<div class="text_name">
								{{item.open_name_follow}}<template v-if="item.profession_follow">/{{item.profession_follow}}</template>
								<span>8月15日 8:15</span>
							</div>
							<div class="text_msg">
								{{item.description_follow}}
							</div>
						</div>
						<div class="btn_right">
							<el-button type="primary" class="btn1" @click="sendmsg(item.who_id_follow)"><!-- 发送消息 -->{{$t('FSXX')}}</el-button>
						
							<el-button type="primary" class="btn2" v-if="item.follow_status==1" @click="guanzhu(item.who_id_follow,item.who_role_follow,index)"><!-- 已互关 -->{{$t('task.YHG')}}</el-button>
							<el-button type="primary" class="btn3" v-else @click="guanzhu(item.who_id_follow,item.who_role_follow,index)"><!-- 回关 -->{{$t('task.HG')}}</el-button>
							
							<!-- <img src="../../assets/img/more.png" class="more" alt=""> -->
						</div>
					</div>
					
					<!-- <div class="list_none" v-if="isLast==1">
						{{$t('task.ZWGDFS')}}
					</div> -->
				</div>
			</div>
			
			
			<!-- 消息列表 -->
			<Homeright></Homeright>
		</div>
		<!-- 回复弹窗 -->
		<!-- <Reply></Reply> -->
		<!-- 全部评论 -->
		<!-- <Pinlun></Pinlun> -->
	</div>
</template>

<script>
	import Homeright from '../../components/home/home_right.vue';
	import scroll from '@/utils/scroll.js';
	import {debounce} from '@/utils/debounce.js';
	export default {
		data() {
			return {
				showIndex:1,
				
				loading:false,
				currentPage: 1,
				listData:{
					keyword: '',
					user_id: '',
					user_role:'',					
					pageSize: 15
				},
				isLast:0,
				list:[],
				userInfo:{},
				fansTotal:0,
				fllowTotal:0
			};
		},
		components: {
			Homeright,
		},
		async created() {
			this.showIndex=this.$route.query.showIndex||1;
			await this.getLogin();
			
			scroll.scrollToBottom(this.getList);
		},
		watch:{
			listData:{
				handler:debounce(function(){
					// console.log('newValue',newValue);
					// console.log('oldValue',oldValue);
					this.getList('reload');
				}),
				deep:true
				
			},
			
		},
		methods:{
			sendmsg(id){
				this.$emit('chat',{user_id:id});
			},
			//返回
			back(){
				this.$router.go(-1);
			},
			//搜索
			search:debounce(function(e){
				console.log(e);
				this.getList('reload');
			}),
			//关注用户
			async guanzhu(id,role,index){
				let res=await this.$request.get('/api/friends/follow',{user_id:id,user_role:role});
				if(this.list[index].is_fans==0){
					this.$util.guanzhu(id);
					this.list[index].is_fans=1;
				}else{
					this.$util.quguan(id);
					this.list[index].is_fans=0;
				}
				this.getList('reload');
			},
			tab(n){
				this.showIndex=n;
				this.getList('reload');
			},
			//获取信息
			async getLogin(){
				let res=await this.$request.get('/api/custuser/getLoginAuthInfo');				
				this.listData.user_role=res.data.user_role;
				this.listData.user_id=res.data.user_id;
			
				let res3=await this.$request.get('/api/custuser/getPersionInfo');
				this.userInfo=res3.data;
				this.fansTotal=res3.data.fans;
				this.fllowTotal=res3.data.follows;
			},
			//获取列表
			  async getList(t){
				if(t=='reload'){
					this.currentPage=1;
					this.isLast=0;		  	
				}
				if(this.isLast==1||this.loading){
					return;
				}
				console.log('加载数据');
				
				let data=JSON.parse(JSON.stringify(this.listData));
				data.currentPage=this.currentPage;
				this.loading=true;
				let urls={1:'/api/friends/findFllowPage',2:'/api/friends/findfansPage'};
				let res=await this.$request.post(urls[this.showIndex],data);
				this.loading=false;
				if(t=='reload'){
					this.list=res.data.records;
				}else{
					if(res.data.records.length>0){
						this.list=[...this.list,...res.data.records];
					}
				}
				if(this.currentPage>=res.data.totalPage){
					this.isLast=1;
				}else{
					this.currentPage+=1;
				}
				
			  }
		}
	};
</script>

<style lang="less" scoped>
	.my_guanz{
		min-height: 100%;
		background-color: #212121;

		.center_left {
			width: 286px;
			min-height: 233px;
			// background: #212121;
			border-radius: 3px;
			color:#fff;
			// border: 2px solid #567BB6;
			// padding: 30px;
			// box-sizing: border-box;
			.btn{
				width: 286px;
				height: 51px;
				background: #212121;
				border-radius: 7px;
				border: 1px solid #567BB6;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #fff;
				box-sizing: border-box;
				margin-bottom: 10px;
			}
			.btn:hover{
				width: 286px;
				height: 51px;
				background: #567BB6;
				border-radius: 7px;
				display: flex;
				align-items: center;
				justify-content: center;
				border: none;
				color: #fff;
				margin-bottom: 10px;
			}
			.active{
				width: 286px;
				height: 51px;
				background: #567BB6;
				border-radius: 7px;
				display: flex;
				align-items: center;
				justify-content: center;
				border: none;
				color: #fff;
				margin-bottom: 10px;
			}
			.el-button{
				margin-left: 0;
			}
			.left_user{
				width: 286px;
				min-height: 169px;
				border-radius: 7px;
				border: 1px solid #567BB6;
				box-sizing: border-box;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-bottom: 20px;
				.user_img{
					width: 103px;
					height: 103px;
					margin-right: 25px;
					border-radius: 10px;
				}
				.user_right{
					width: 100px;
					.right_name{
						font-size: 15px;
						font-weight: bold;
						margin-bottom: 10px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
					.right_msg{
						font-size: 12px;
						margin-bottom: 10px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}
			}
			.left_tui{
				font-size: 15px;
				padding: 30px;
				background-color: #567BB6;
				border-radius: 3px;
				.tui_title{
					font-weight: bold;
					margin-bottom: 10px;
				}
				.tui_box{
					padding: 20px 0;
					border-top: 1px solid #FFFFFF;
					line-height: 1.3;
					.tui_list{
						font-size: 12px;
						margin-bottom: 5px;
					}
					.tui_btn{
						width: 208px;
						height: 34px;
						background: #FFFFFF;
						border-radius: 3px;
						border: 1px solid #FFFFFF;
						display: flex;
						align-items: center;
						justify-content: center;
						color: #212121;
						margin-top: 10px;
					}
				}
				.tui_change{
					width: 208px;
					height: 34px;
					border-radius: 3px;
					display: flex;
					align-items: center;
					justify-content: center;
					background-color: #567BB6;
					border: 1px solid #FFFFFF;
				}
				
			}
			
		}

		.home_center {
			display: flex;
			justify-content: flex-end;
		}

		.center_people {
			padding: 20px;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding-top: 0;
			.people_top{
				width: 878px;
				height: 69px;
				background: #F3F1EA;
				border-radius: 7px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 0 20px;
				box-sizing: border-box;
				margin-bottom: 20px;
				.top_left_box{
					width: 189px;
					display: flex;
					align-items: center;
					font-size: 15px;
					.top_left{
						width: 20px;
						height: 20px;
					}
				}
				.top_center{
					font-weight: bold;
				}
				.input_right{
					width: 189px;
				}
				
			}

			.people_list{
				width: 878px;
				box-sizing: border-box;
				padding: 20px;
				background: #F3F1EA;
				border-radius: 7px;
				.list_box{
					height: 84px;
					border-bottom: 1px solid #567BB6;
					display: flex;
					align-items: center;
					justify-content: space-between;
					.list_img{
						width: 43px;
						height: 43px;
						margin-right: 15px;
						img{width: 100%;height: 100%;}
					}
					.list_text{
						flex:1;
						.text_name{
							display: flex;
							align-items: center;
							font-size: 14px;
							color: #2D2D2E;
							margin-bottom: 10px;
							font-weight: bold;
							span{
								font-weight: normal;
								font-size: 12px;
								margin-left: 10px;
							}
						}
						.text_msg{
							font-size: 12px;
							line-height: 1.3;
							text-overflow: ellipsis;
							overflow: hidden;
							white-space: nowrap;
						}
					}
					.btn_right{
						display: flex;
						align-items: center;
						.more{
							width: 27px;
							height: 27px;
							margin-left: 15px;
						}
						.btn1{
							min-width: 86px;
							height: 34px;
							background: #567BB6;
							border-radius: 7px;
							display: flex;
							align-items: center;
							justify-content: center;
							border: none;
						}
						.btn2{
							min-width: 86px;
							height: 34px;
							border: 1px solid #567BB6;
							background-color: #F3F1EA;
							color: #2D2D2E;
							border-radius: 7px;
							display: flex;
							align-items: center;
							justify-content: center;
						}
						.btn3{
							min-width: 86px;
							height: 34px;
							border: 1px solid #B65664;
							background-color: #F3F1EA;
							color: #B65664;
							border-radius: 7px;
							display: flex;
							align-items: center;
							justify-content: center;
						}
					}
				}
				.list_none{
					text-align: center;
					font-size: 14px;
					line-height: 40px;
					font-weight: bold;
					color: #2D2D2E;
				}
			}

		}
	}
</style>
